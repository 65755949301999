import Box from "@mui/material/Box";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Typography from "@mui/material/Typography";

const FEATURES = [
  "Download patterns instantly",
  "Create custom patterns using any image",
  "User-friendly program makes pattern creation simple",
  "Try before subscribing with single pattern purchases",
  "Create unlimited patterns with flexible subscription options",
  "Cancel anytime with no commitments",
  "Makes a thoughtful and creative gift",
  "Save ink with ink-saver strip options",
  "Create stunning lenticular patterns",
  "Easy-to-follow patterns for all skill levels",
];

export const FeatureList = () => {
  return (
    <div style={{ marginBottom: "80px", marginTop: "80px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography
          variant="h2"
          style={{
            textAlign: "center",
            lineHeight: "42px",
            marginBottom: "20px",
          }}
        >
          Pattern creation made{" "}
          <span
            style={{
              background: "linear-gradient(90deg, #ff7e5f, #feb47b)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            easy
          </span>
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {FEATURES.map((feature) => (
            <Box
              key={feature}
              sx={{ py: 0.5, display: "flex", gap: 1.5, alignItems: "center" }}
            >
              <CheckCircleRoundedIcon
                sx={[
                  {
                    width: 40,
                    color: "#83c021",
                  },
                ]}
              />
              <Typography
                variant="h5"
                component={"span"}
                sx={[
                  {
                    color: "text.primary",
                  },
                ]}
              >
                {feature}
              </Typography>
            </Box>
          ))}
        </div>
      </div>
    </div>
  );
};
