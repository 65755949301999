import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ImageBrowseButton from "./Shared/ImageBrowseButton";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Popover from "@mui/material/Popover";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import { ANGLE_LINES, IMAGETYPE } from "../../js/constants";
import { PatternContext } from "../../Context/PatternProvider";

export const BrowseImage = (props) => {
  const { onNextClick } = props;

  const {
    imagePrimaryDataURLState,
    imageSecondaryDataURLState,
    imageTypeState,
    angleLineState,
    isInkSaverState,
  } = useContext(PatternContext);

  const [imagePrimaryDataURL, setImagePrimaryDataURL, imagePrimaryJimpError] =
    imagePrimaryDataURLState;
  const [
    imageSecondaryDataURL,
    setImageSecondaryDataURL,
    imageSecondaryJimpError,
  ] = imageSecondaryDataURLState;
  const [imageType, setImageType] = imageTypeState;
  const [angleLine, setAngleLine] = angleLineState;
  const [isInkSaver, setIsInkSaver] = isInkSaverState;

  const [showAdvancedModal, setShowAdvancedModal] = useState(false);
  const onShowAdvancedClick = () => {
    setShowAdvancedModal(true);
  };
  const AdvancedSettingsModal = () => {
    // Ink saver
    const [anchorInfoInkSaver, setAnchorInfoInkSaver] = useState(null);
    const openPopoverInkSaver = Boolean(anchorInfoInkSaver);
    const inkSaverPopupId = openPopoverInkSaver
      ? "ink-saver-popover"
      : undefined;
    const handleInfoInkSaverClick = (event) => {
      setAnchorInfoInkSaver(event.currentTarget);
    };
    const handleInfoInkSaverClose = () => {
      setAnchorInfoInkSaver(null);
    };
    const handleInkSaverChange = (event) => {
      setIsInkSaver(event.target.checked);
    };

    //Angle line
    const [anchorInfoAngleLine, setAnchorInfoAngleLine] = useState(null);
    const openPopoverAngleLine = Boolean(anchorInfoAngleLine);
    const angleLinePopupId = openPopoverAngleLine
      ? "angle-line-popover"
      : undefined;
    const handleInfoAngleLineClick = (event) => {
      setAnchorInfoAngleLine(event.currentTarget);
    };
    const handleInfoAngleLineClose = () => {
      setAnchorInfoAngleLine(null);
    };
    const handleAngleLineChange = (event) => {
      setAngleLine(event.target.value);
    };

    return (
      <>
        {showAdvancedModal && (
          <Modal
            open={showAdvancedModal}
            onClose={() => setShowAdvancedModal(false)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(3px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "15px",
              }}
            >
              <IconButton
                edge="end"
                aria-label="Menu"
                onClick={() => {
                  setShowAdvancedModal(false);
                }}
                style={{
                  alignSelf: "flex-end",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <CloseIcon />
              </IconButton>

              <Typography
                variant="h3"
                style={{ lineHeight: "3rem", paddingBottom: "15px" }}
              >
                Advanced Settings
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <FormControl>
                  <FormLabel>
                    <Typography variant="h5">Pattern Type</Typography>
                  </FormLabel>
                  <RadioGroup
                    value={imageType}
                    onChange={handleImageTypeChange}
                  >
                    <FormControlLabel
                      value={IMAGETYPE.SINGLE}
                      control={<Radio />}
                      label="Single Image"
                      componentsProps={{ typography: { variant: "h5" } }}
                    />
                    <FormControlLabel
                      value={IMAGETYPE.DOUBLE}
                      control={<Radio />}
                      label="Lenticular"
                      componentsProps={{ typography: { variant: "h5" } }}
                    />
                  </RadioGroup>
                </FormControl>

                <div style={{ flexGrow: 1 }} />

                <FormControl>
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isInkSaver}
                          onChange={handleInkSaverChange}
                        />
                      }
                      label="Ink Saver"
                      componentsProps={{ typography: { variant: "h5" } }}
                    />
                    <InfoOutlinedIcon
                      onClick={handleInfoInkSaverClick}
                      fontSize="small"
                    />
                  </div>

                  <Popover
                    id={inkSaverPopupId}
                    open={openPopoverInkSaver}
                    anchorEl={anchorInfoInkSaver}
                    onClose={handleInfoInkSaverClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      Use thinner strips to save on ink
                    </Typography>
                  </Popover>
                </FormControl>

                <div style={{ flexGrow: 1 }} />

                <FormControl>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <>
                          <Select
                            variant="standard"
                            value={angleLine}
                            label="Angle Line"
                            onChange={handleAngleLineChange}
                            style={{ fontSize: "1.5rem", color: "#5E686B" }}
                          >
                            <MenuItem
                              value={ANGLE_LINES.NONE}
                              style={{ fontSize: "1.5rem", color: "#5E686B" }}
                            >
                              {ANGLE_LINES.NONE}
                            </MenuItem>
                            <MenuItem
                              value={ANGLE_LINES.TOPONLY}
                              style={{ fontSize: "1.5rem", color: "#5E686B" }}
                            >
                              {ANGLE_LINES.TOPONLY}
                            </MenuItem>
                            <MenuItem
                              value={ANGLE_LINES.BOTTOMONLY}
                              style={{ fontSize: "1.5rem", color: "#5E686B" }}
                            >
                              {ANGLE_LINES.BOTTOMONLY}
                            </MenuItem>
                            <MenuItem
                              value={ANGLE_LINES.TOPANDBOTTOM}
                              style={{ fontSize: "1.5rem", color: "#5E686B" }}
                            >
                              {ANGLE_LINES.TOPANDBOTTOM}
                            </MenuItem>
                          </Select>
                        </>
                      }
                      label={
                        <Typography variant="h5" style={{ marginLeft: "10px" }}>
                          45° cut lines
                        </Typography>
                      }
                    />
                    <InfoOutlinedIcon
                      onClick={handleInfoAngleLineClick}
                      fontSize="small"
                    />
                  </div>

                  <Popover
                    id={angleLinePopupId}
                    open={openPopoverAngleLine}
                    anchorEl={anchorInfoAngleLine}
                    onClose={handleInfoAngleLineClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      Show lines to guide angle cuts at the top and bottom of
                      each strip
                    </Typography>
                  </Popover>
                </FormControl>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  };

  const handleImageTypeChange = (event) => {
    setImageType(event.target.value);
  };

  const isNextButtonBisabled = () => {
    const isPrimaryImageValid = imagePrimaryDataURL && !imagePrimaryJimpError;
    const isSecondaryImageValid =
      imageSecondaryDataURL && !imageSecondaryJimpError;

    if (imageType === IMAGETYPE.SINGLE) {
      return isPrimaryImageValid ? false : true;
    }
    //Double
    return isPrimaryImageValid && isSecondaryImageValid ? false : true;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "300px",
        borderRadius: "15px",
        backgroundColor: "#FEFEFF",
        padding: "16px",
        flexWrap: "wrap",
      }}
    >
      <Button
        variant="text"
        size="large"
        color="inherit"
        onClick={onShowAdvancedClick}
        endIcon={<TuneIcon />}
        style={{
          marginBottom: "20px",
          fontSize: "20px",
        }}
      >
        <Typography
          variant="h5"
          style={{
            marginTop: "5px",
          }}
        >
          Advanced Settings
        </Typography>
      </Button>
      <AdvancedSettingsModal />

      <div style={{ flexGrow: 1 }} />

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {/* Primary Image */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "300px",
          }}
        >
          <ImageBrowseButton
            id={"1"}
            imageDataURL={imagePrimaryDataURL}
            callback={setImagePrimaryDataURL}
          />
          {imagePrimaryJimpError && (
            <Tooltip
              title={
                <Typography variant="h5">{imagePrimaryJimpError}</Typography>
              }
            >
              <WarningIcon color="warning" style={{ margin: "10px" }} />
            </Tooltip>
          )}
        </div>

        {/* Secondary Image */}
        {imageType === IMAGETYPE.DOUBLE && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "300px",
            }}
          >
            <ImageBrowseButton
              id={"2"}
              imageDataURL={imageSecondaryDataURL}
              callback={setImageSecondaryDataURL}
            />
            {imageSecondaryJimpError && (
              <Tooltip
                title={
                  <Typography variant="h5">
                    {imageSecondaryJimpError}
                  </Typography>
                }
              >
                <WarningIcon color="warning" style={{ margin: "10px" }} />
              </Tooltip>
            )}
          </div>
        )}
      </div>

      <div style={{ flexGrow: 1 }} />

      <Button
        variant="contained"
        style={{ boxShadow: "none" }}
        disabled={isNextButtonBisabled()}
        onClick={onNextClick}
      >
        <Typography variant="h5" style={{ marginBottom: "-5px" }}>
          Next
        </Typography>
      </Button>
    </div>
  );
};
