import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "./Context/SnackBarProvider";
import { AuthProvider } from "./Context/AuthProvider";
import { FirebaseAppProvider } from "./Context/FirebaseAppProvider";
import { SignedInCheck } from "./Components/Account/SignedInCheck";
import { Faq } from "./Components/LandingPage/Faq";
import { SinglePattern } from "./Components/SinglePattern";
import { PostHogProvider } from "posthog-js/react";
import Main from "./Components/Main/Index";
import { LandingPage } from "./Components/LandingPage/LandingPage";

const options = {
  api_host: "https://us.i.posthog.com",
};

const App = () => {
  return (
    <PostHogProvider
      apiKey={"phc_kCd1SCQU8hXHOBOaSnqsJqXs6s1q3cYD1hCBrPdVUun"}
      options={options}
    >
      <FirebaseAppProvider>
        <AuthProvider>
          <SnackbarProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <SignedInCheck LandingPage={LandingPage} Main={Main} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <SignedInCheck LandingPage={LandingPage} Main={Main} />
                  }
                />
                <Route path="/PatternMaker" element={<Main />} />
                <Route path="/sp/*" element={<SinglePattern />} />
              </Routes>
            </BrowserRouter>
          </SnackbarProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </PostHogProvider>
  );
};

export default App;
