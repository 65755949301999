import PDFDocument from "pdfkit/js/pdfkit.standalone";
import {
  BOOK_INFO_DEFAULTS,
  ANGLE_LINES,
  MEASUREMENT_UNITS,
  MEASUREMENT_UNIT,
} from "../js/constants";
import {
  getHeightCM,
  mmToPoints,
  getHeightInchPrimary,
  getHeightInchFraction,
} from "../js/numbers";

const BuildPattern = async (props) => {
  return new Promise(async (resolve, reject) => {
    let {
      angleLine,
      isInkSaver,
      imagePrimaryDataURL,
      imageSecondaryDataURL,
      height,
      lastOddPage,
      measurementUnit,
      subscribed = false,
    } = props;

    //input Validation
    height = Number(height);
    lastOddPage = Number(lastOddPage);
    if (
      height > BOOK_INFO_DEFAULTS.MAX_HEIGHT ||
      lastOddPage > BOOK_INFO_DEFAULTS.MAX_LAST_ODD_PAGE
    ) {
      return null;
    }
    const pages = (lastOddPage + 1) / 2;

    const { Jimp, blobStream } = window;
    const previewImageWidth = 200;
    const previewImageHeight = 200;
    const leftMargin = 36;
    const rightMargin = 576;
    const topMargin = height > 260 ? 0 : 27; // If height is greater than 260mm, start strips at the top of the page
    const bottomMargin = 756;
    const docWidth = 540;
    const stripsPerPage = isInkSaver ? 12 : 10;
    const stripWidth = docWidth / stripsPerPage;
    const stripHeight = mmToPoints(height);
    const rowGap = 30; // Gap between rows to avoid interference
    const stripOptions = {
      width: stripWidth / (imageSecondaryDataURL ? 4 : 2),
      height: stripHeight,
    };

    const maxRowsPerPage = Math.max(
      1,
      Math.floor((bottomMargin - topMargin) / (stripHeight + rowGap))
    );

    const doc = new PDFDocument();
    const font = await fetch(
      `${process.env.PUBLIC_URL}/fonts/Roboto-Regular.ttf`
    );
    const arrayBuffer = await font.arrayBuffer();
    doc.registerFont("Roboto", arrayBuffer);
    doc.font("Roboto");
    const stream = doc.pipe(blobStream());
    stream.on("finish", () => {
      const url = stream.toBlobURL("application/pdf");
      let blob = stream.toBlob("application/pdf");
      resolve({ url, blob });
    });

    //Convert base64 image to Jimp image
    let jimpPrimaryImage = await Jimp.read(imagePrimaryDataURL);
    let jimpSecondaryImage;
    if (imageSecondaryDataURL) {
      jimpSecondaryImage = await Jimp.read(imageSecondaryDataURL);
    }

    //Preview Text
    if (!subscribed) {
      doc
        .fontSize(11)
        .fillColor("black")
        .text("This is only a partial pattern", leftMargin, 10, {
          width: docWidth,
          align: "center",
        });
      doc
        .fontSize(8)
        .fillColor("black")
        .text("Subscribe now to create unlimited patterns", leftMargin, 30, {
          width: docWidth,
          align: "center",
        });
      doc
        .fontSize(8)
        .fillColor("black")
        .text("or purchase this pattern individually", leftMargin, 40, {
          width: docWidth,
          align: "center",
        });
    }

    //Logo
    let logo = await Jimp.read(`${process.env.PUBLIC_URL}/Logo.png`);
    doc.image(await logo.getBase64Async(Jimp.AUTO), docWidth / 2 + 10, 45, {
      width: 50,
      height: 50,
      align: "center",
    });
    doc
      .fontSize(11)
      .fillColor("black")
      .text("ForeEdgeBookArt.com", leftMargin, 90, {
        width: docWidth,
        align: "center",
      });

    //Preview page
    if (imageSecondaryDataURL) {
      const maxDimension = 200; // Maximum width or height for each image

      // Load and get properties of the primary image
      const originalPrimaryWidth = jimpPrimaryImage.bitmap.width;
      const originalPrimaryHeight = jimpPrimaryImage.bitmap.height;
      const primaryAspectRatio = originalPrimaryWidth / originalPrimaryHeight;
      let primaryWidthToFit = maxDimension;
      let primaryHeightToFit = primaryWidthToFit / primaryAspectRatio;
      if (primaryHeightToFit > maxDimension) {
        primaryHeightToFit = maxDimension;
        primaryWidthToFit = primaryHeightToFit * primaryAspectRatio;
      }

      // Calculate center position for the primary image
      const primaryCenterX =
        leftMargin + (docWidth / 4 - primaryWidthToFit / 2);
      const verticalRange = 310 - 110; // Total vertical space available
      const primaryY = 110 + (verticalRange / 2 - primaryHeightToFit / 2); // Centering vertically within 110 to 310

      // Load and get properties of the secondary image
      const originalSecondaryWidth = jimpSecondaryImage.bitmap.width;
      const originalSecondaryHeight = jimpSecondaryImage.bitmap.height;
      const secondaryAspectRatio =
        originalSecondaryWidth / originalSecondaryHeight;
      let secondaryWidthToFit = maxDimension;
      let secondaryHeightToFit = secondaryWidthToFit / secondaryAspectRatio;
      if (secondaryHeightToFit > maxDimension) {
        secondaryHeightToFit = maxDimension;
        secondaryWidthToFit = secondaryHeightToFit * secondaryAspectRatio;
      }

      // Calculate center position for the secondary image
      const secondaryCenterX =
        leftMargin + (3 * docWidth) / 4 - secondaryWidthToFit / 2;
      const secondaryY = 110 + (verticalRange / 2 - secondaryHeightToFit / 2); // Centering vertically within 110 to 310

      // Place the primary image
      doc.image(
        await jimpPrimaryImage.getBase64Async(Jimp.AUTO),
        primaryCenterX,
        primaryY,
        { width: primaryWidthToFit, height: primaryHeightToFit }
      );

      // Place the secondary image
      doc.image(
        await jimpSecondaryImage.getBase64Async(Jimp.AUTO),
        secondaryCenterX,
        secondaryY,
        { width: secondaryWidthToFit, height: secondaryHeightToFit }
      );
    } else {
      const maxHeight = 300;
      const maxWidth = docWidth - 2 * leftMargin; // Define the maximum width the image can have without exceeding the page's width

      const originalImageHeight = jimpPrimaryImage.bitmap.height;
      const originalImageWidth = jimpPrimaryImage.bitmap.width;
      const aspectRatio = originalImageWidth / originalImageHeight;

      // Calculate initial width and height based on max height and aspect ratio
      let widthToFit = maxHeight * aspectRatio;
      let heightToFit = maxHeight;

      // Adjust width and height if the width exceeds the maximum allowable width
      if (widthToFit > maxWidth) {
        widthToFit = maxWidth; // Adjust width to fit within the page
        heightToFit = widthToFit / aspectRatio; // Recalculate height to maintain aspect ratio
      }

      // Center the image horizontally
      const centerX = leftMargin + (docWidth / 2 - widthToFit / 2);

      // Define vertical bounds and calculate the center point
      const topBound = 110;
      const bottomBound = 410;
      const verticalCenter = topBound + (bottomBound - topBound) / 2;

      // Calculate y-coordinate to center the image vertically within the bounds
      const centerY = verticalCenter - heightToFit / 2;

      doc.image(
        await jimpPrimaryImage.getBase64Async(Jimp.AUTO),
        centerX,
        centerY, // Use centerY for vertical centering
        {
          width: widthToFit,
          height: heightToFit,
        }
      );
    }

    //Pattern Information
    let heightLabelYAxis = imageSecondaryDataURL ? 320 : 420;
    const measurementUnitLabel = MEASUREMENT_UNITS.find(
      (unit) => unit.value === measurementUnit
    ).label;
    let heightLabel;
    if (measurementUnit === MEASUREMENT_UNIT.cm) {
      heightLabel = getHeightCM(height) + measurementUnitLabel;
    } else if (measurementUnit === MEASUREMENT_UNIT.inch) {
      let fraction = getHeightInchFraction(height).label;
      if (fraction === "-") {
        fraction = "";
      }
      heightLabel = `${getHeightInchPrimary(
        height
      )}${fraction} ${measurementUnitLabel}`;
    }
    //Pattern Height
    doc
      .fontSize(11)
      .fillColor("black")
      .text(`Height: ${heightLabel}`, leftMargin, heightLabelYAxis, {
        width: docWidth,
        align: "center",
      });
    //Last odd page
    doc
      .fontSize(11)
      .fillColor("black")
      .text(
        `Last Odd Page: ${lastOddPage}`,
        leftMargin,
        heightLabelYAxis + 15,
        { width: docWidth, align: "center" }
      );

    // Pattern

    doc.addPage();

    //squish image horizontally
    let resizedPrimaryImage = await jimpPrimaryImage.resize(
      pages,
      jimpPrimaryImage.bitmap.height
    );
    let resizedSecondaryImage;
    if (imageSecondaryDataURL) {
      resizedSecondaryImage = await jimpSecondaryImage.resize(
        pages,
        jimpSecondaryImage.bitmap.height
      );
    }

    let stripNumber = 0;
    let rowNumber = 0;

    for (let i = 0; i < pages; i++) {
      const x = leftMargin + stripNumber * stripWidth;
      const y = topMargin + rowNumber * (stripHeight + rowGap);
      const number = i + 1;
      const oddPageNumber = number * 2 - 1;

      //Primary Strip
      //Read image - for some reason this needs to be done each iteration
      let resizedPrimaryJimpImage = await Jimp.read(resizedPrimaryImage);
      let stripPrimary = await resizedPrimaryJimpImage
        .crop(i, 0, 1, jimpPrimaryImage.bitmap.height)
        .getBase64Async(Jimp.AUTO);

      //Secondary Strip
      let resizedSecondaryJimpImage;
      let stripSecondary;
      if (imageSecondaryDataURL) {
        resizedSecondaryJimpImage = await Jimp.read(resizedSecondaryImage);
        stripSecondary = await resizedSecondaryJimpImage
          .crop(i, 0, 1, jimpSecondaryImage.bitmap.height)
          .getBase64Async(Jimp.AUTO);
      }

      //Cut line at the beginning of a page
      if (stripNumber === 0) {
        //Cut line
        doc
          .lineCap("butt")
          .lineWidth(1)
          .moveTo(x, y)
          .lineTo(x, y + stripHeight)
          .strokeColor("#404040")
          .stroke();
      }

      //Sideways strip number
      let stripNumberOffset = angleLine === ANGLE_LINES.NONE ? 15 : 25;
      doc.rotate(90, { origin: [x, y] });
      doc
        .fontSize(8)
        .fillColor("black")
        .text("< " + oddPageNumber, x + stripNumberOffset, y - 10, {
          width: 40,
          align: "left",
        });
      doc.rotate(-90, { origin: [x, y] });

      //Watermark
      if (stripNumber % 4 === 0) {
        doc.rotate(90, { origin: [x, y] });
        doc
          .fontSize(7)
          .fillColor("#7A7A7A")
          .text("ForeEdgeBookArt.com", x + (stripHeight / 2 - 50), y - 10, {
            width: 100,
            align: "center",
          });
        doc.rotate(-90, { origin: [x, y] });
      }

      //Top Fold line
      doc
        .lineCap("butt")
        .lineWidth(2)
        .undash()
        .moveTo(x + stripWidth / 2, y - 20)
        .lineTo(x + stripWidth / 2, y - 10)
        .strokeColor("black")
        .stroke();

      //Image strip
      doc.image(stripPrimary, x + stripWidth / 4, y, stripOptions);
      if (imageSecondaryDataURL) {
        doc.image(
          stripSecondary,
          x + stripWidth / 4 + stripOptions.width,
          y,
          stripOptions
        );
      }

      //45deg cut lines
      if ([ANGLE_LINES.TOPONLY, ANGLE_LINES.TOPANDBOTTOM].includes(angleLine)) {
        //45deg cut line at top left of strip
        doc
          .lineCap("butt")
          .lineWidth(1)
          .undash()
          .moveTo(x, y + stripWidth / 2)
          .lineTo(x + stripWidth / 2 - 5, y + 5)
          .strokeColor("#7a7a7a")
          .stroke();
        //45deg cut line at top right of strip
        doc
          .lineCap("butt")
          .lineWidth(1)
          .undash()
          .moveTo(x + stripWidth, y + stripWidth / 2)
          .lineTo(x + stripWidth / 2 + 5, y + 5)
          .strokeColor("#7a7a7a")
          .stroke();
      }
      if (
        [ANGLE_LINES.BOTTOMONLY, ANGLE_LINES.TOPANDBOTTOM].includes(angleLine)
      ) {
        //45deg cut line at bottom left of strip
        doc
          .lineCap("butt")
          .lineWidth(1)
          .undash()
          .moveTo(x, y + stripHeight - stripWidth / 2)
          .lineTo(x + stripWidth / 2 - 5, y + stripHeight - 5)
          .strokeColor("#7a7a7a")
          .stroke();
        //45deg cut line at bottom right of strip
        doc
          .lineCap("butt")
          .lineWidth(1)
          .undash()
          .moveTo(x + stripWidth, y + stripHeight - stripWidth / 2)
          .lineTo(x + stripWidth / 2 + 5, y + stripHeight - 5)
          .strokeColor("#7a7a7a")
          .stroke();
      }

      //Bottom Fold line
      doc
        .lineCap("butt")
        .lineWidth(2)
        .undash()
        .moveTo(x + stripWidth / 2, y + stripHeight + 10)
        .lineTo(x + stripWidth / 2, y + stripHeight + 20)
        .strokeColor("black")
        .stroke();

      stripNumber++;

      //Cut line between image strips
      if (stripNumber < stripsPerPage && i + 1 !== pages) {
        //Cut line
        doc
          .lineCap("butt")
          .lineWidth(1)
          .moveTo(x + stripWidth, y)
          .lineTo(x + stripWidth, y + stripHeight)
          .strokeColor("#404040")
          .stroke();
      }

      //Cut line at the end of a row and there are more strips to be added or this is the last strip
      if (stripNumber === stripsPerPage || i + 1 === pages) {
        //Cut line
        doc
          .lineCap("butt")
          .lineWidth(1)
          .moveTo(x + stripWidth, y)
          .lineTo(x + stripWidth, y + stripHeight)
          .strokeColor("#404040")
          .stroke();
      }

      // Move to the next row if the current row is full
      if (stripNumber >= stripsPerPage) {
        stripNumber = 0;
        rowNumber++;
      }

      // Add new page if subscribed, no more rows will fit, and there are still more image strips to add
      if (subscribed && rowNumber >= maxRowsPerPage && i + 1 !== pages) {
        stripNumber = 0;
        rowNumber = 0;
        doc.addPage();
      }

      // If not subscribed, don't add any more strip pages
      if (!subscribed && rowNumber >= maxRowsPerPage) {
        break;
      }
    }
    doc.end();
  });
};

export default BuildPattern;
